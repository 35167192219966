import React, {useEffect, useRef} from 'react';

import './swipebox.css';

interface SwipeBox  {
    id: number,
    img: string
}

interface Props {
    itemId: number,
    items: SwipeBox[]
    onChange: (itemId: number) => void

    height: number,
    onClick?: (itemId: number) => void,
}

export const Swipebox = ({itemId, items, height, onClick, onChange}: Props) => {
    const swipeBox = useRef<HTMLDivElement | null>(null);
    const container = useRef<HTMLDivElement | null>(null);
    const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

    const start = useRef(0);
    const innerItemId = useRef(0);
    const mainPosition = useRef(0);
    const mouseDown = useRef(false);

    const startSwipe = (e: any) => {
        e.preventDefault();
        mouseDown.current = true;

        if (typeof e.changedTouches !== 'undefined') {
            start.current = e.changedTouches[0].clientX;
        } else {
            start.current = e.pageX;
        }
    };

    const swiping = (e: any) => {
        if (container.current && mouseDown.current) {
            e.preventDefault();
            e.stopPropagation();

            const difference = !!e.changedTouches
                ? e.changedTouches[0].pageX - start.current
                : e.pageX - start.current;

            container.current.style.transition = 'none';
            container.current.style.left = mainPosition.current + difference + "px";
        }
    };

    const swipeToRight = () => {
        if (container.current && innerItemId.current < (container.current.children.length) - 1) {
            const newItemId = innerItemId.current + 1;
            const item = itemsRef.current[newItemId];

            if (item) {
                const newPosition = item.offsetLeft * -1;
                mainPosition.current = newPosition;
                container.current.style.left = newPosition + "px";

                onChange(newItemId);
                innerItemId.current = newItemId;
            }
        } else {
            const item = itemsRef.current[innerItemId.current];
            if (item && container.current) {
                const newPosition = item.offsetLeft * -1;
                mainPosition.current = newPosition;
                container.current.style.left = newPosition + "px";
            }
        }
    }

    const swipeToLeft = () => {
        if (container.current && innerItemId.current > 0) {
            const newItemId = innerItemId.current - 1;
            const item = itemsRef.current[newItemId];

            if (item) {
                const newPosition = item.offsetLeft * -1;
                mainPosition.current = newPosition;
                container.current.style.left = newPosition + "px";

                innerItemId.current = newItemId;
                onChange(newItemId);
            }
        } else {
            const item = itemsRef.current[innerItemId.current];
            if (item && container.current) {
                const newPosition = item.offsetLeft * -1;

                mainPosition.current = newPosition;
                container.current.style.left = newPosition + "px";
            }
        }
    }

    const endSwipe = (e: any) => {
        if (container.current) {
            mouseDown.current = false;
            let difference: number;

            if (typeof e.changedTouches !== 'undefined') {
                difference = e.changedTouches[0].pageX - start.current;
            } else {
                difference = e.pageX - start.current;
            }
            mainPosition.current = mainPosition.current + difference;
            container.current.style.transition = 'all 0.3s';

            if (difference < 0) {
                swipeToRight();
            } else if (difference > 0) {
                swipeToLeft();
            } else if (difference === 0) {
                if (onClick) {
                    onClick(innerItemId.current);
                }
            }
        }
    };

    const goToItem = (targetId: number) => {
        if (container.current && targetId >= 0 && targetId <= container.current.children.length - 1) {
            container.current.style.transition = 'all 0.3s';
            innerItemId.current = targetId;
            const targetItem = itemsRef.current[targetId];
            if (targetItem) {
                mainPosition.current = targetItem.offsetLeft * -1;
                container.current.style.left = targetItem.offsetLeft * -1 + "px";
            }
        }
    }

    const mouseOut = (e: any) => {
        if (mouseDown.current) {
           endSwipe(e);
        }
    };

    const observeResize = () => {
        const currentItem = itemsRef.current[itemId];
        if (itemsRef.current && currentItem && container.current) {
            start.current = 0;
            mainPosition.current = currentItem.offsetLeft * -1;
            container.current.style.left = mainPosition.current + "px";
        }
    }

    useEffect(() => {
        const swipeBoxElement = swipeBox.current;
        if (swipeBoxElement) {


            swipeBoxElement.addEventListener("mousedown", startSwipe, true)
            swipeBoxElement.addEventListener("mousemove", swiping, true)
            swipeBoxElement.addEventListener("mouseup", endSwipe, true)
            swipeBoxElement.addEventListener("mouseout", mouseOut, true)

            swipeBoxElement.addEventListener("touchstart", startSwipe, true)
            swipeBoxElement.addEventListener("touchmove", swiping, true)
            swipeBoxElement.addEventListener("touchend", endSwipe, true)

            window.addEventListener('resize', observeResize);
        }

        return () => {
            if (swipeBoxElement) {
                swipeBoxElement.removeEventListener('mousedown', startSwipe);
                swipeBoxElement.removeEventListener("mousemove", swiping);
                swipeBoxElement.removeEventListener("mouseup", endSwipe);
                swipeBoxElement.removeEventListener("mouseout", mouseOut);

                swipeBoxElement.removeEventListener("touchstart", startSwipe);
                swipeBoxElement.removeEventListener("touchmove", swiping);
                swipeBoxElement.removeEventListener("touchend", endSwipe);

                window.removeEventListener('resize', observeResize);
            }
        }
    }, []);

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, items.length);
    }, [items]);

    useEffect(() => {
        if (innerItemId.current !== itemId) {
            goToItem(itemId);
        }
    }, [itemId]);

    return (
        <div className="swipebox" style={{height}} ref={swipeBox}>
            <div className="swipebox__container" ref={container}>
                {items.map(({id, img}, i) => (
                    <img
                        loading="lazy"
                        alt={img}
                        key={id}
                        ref={el => itemsRef.current[i] = el}
                        className="swipebox__item"
                        src={img}
                    />
                ))}
            </div>
        </div>
    );
};
