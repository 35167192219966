import React, {useEffect, useState} from "react";

import {DeviceContext} from "./device-context";
import {debounce} from "../../utils/debounce";

const MAX_MOBILE_WIDTH = 800;
const ON_RESIZE_UPDATE_DELAY = 1000;

interface Props {
    children: React.ReactNode
}

export const DeviceContextProvider = ({children}: Props) => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > MAX_MOBILE_WIDTH);

    const getDevice = debounce(() => {
        setIsDesktop(window.screen.width > MAX_MOBILE_WIDTH);
    }, ON_RESIZE_UPDATE_DELAY);

    useEffect(() => {
        window.addEventListener('resize', getDevice);

        return () => {
            window.removeEventListener('resize', getDevice);
        }
    }, []);

    return (
        <DeviceContext.Provider value={{isDesktop}}>
            {children}
        </DeviceContext.Provider>
    )
}