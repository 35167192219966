import clsx from 'clsx';
import './button.css';

export enum ButtonType {
    PRIMARY,
    SECONDARY
}

interface Props {
    caption: string,
    type: ButtonType,
    handler: () => void
}

export const Button = ({caption, type, handler}: Props) => {
    const classes = clsx('button', [
        type === ButtonType.PRIMARY && 'button_primary',
        type === ButtonType.SECONDARY && 'button_secondary'
    ]);

    return (
        <button className={classes} onClick={handler}>
            {caption}
        </button>
    );
};
