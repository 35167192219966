import clsx from 'clsx';

interface Props {
    isSelected: boolean,
    onClick: () => void
}

export const GalleryIndicator = ({isSelected, onClick}: Props) => {
    const indicatorCLasses = clsx('gallery-preview__indicator', [
        isSelected && 'gallery-preview__indicator_selected'
    ]);

    return (
        <li onClick={onClick} className={indicatorCLasses}></li>
    );
};
