import clsx from 'clsx';
import React, {useContext} from 'react';

import {DeviceContext} from '../../../../contexts/device-context';

import './landing-section.css';

interface Props {
    href: string,
    title: string,
    children: React.ReactNode;
}

export const LandingSection = ({href, title, children}: Props) => {
    const {isDesktop} = useContext(DeviceContext);

    const sectionClasses = clsx('landing-section', [
        isDesktop && 'landing-section_desktop',
        !isDesktop && 'landing-section_mobile'
    ]);

    return (
        <section id={href} className={sectionClasses}>
            <h1 className="landing-section__header">{title}</h1>
            <div className="landing-section__content">{children}</div>
        </section>
    );
};
