import React, {useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../../../components/button';
import {ButtonType} from '../../../../components/button/button';

import type {OfferItem} from '../../../../contexts/config-context/config-context';
import {GalleryFiltersContext} from "../../../../contexts/gallery-filters-context";

interface Props {
    offer: OfferItem
}

export const Solution = ({offer}: Props) => {
    const {setCategoryFilter} = useContext(GalleryFiltersContext);
    let navigate = useNavigate();

    const redirectToGallery = useCallback(() => {
        setCategoryFilter(offer.categoryId);
        navigate('/gallery#gallery');
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="solutions__item">
            <img className="solutions-item__image" src={offer.img} alt={offer.categoryId.toString()}/>
            <div className="solutions-item__description">{offer.description}</div>
            <Button caption="Learn more" type={ButtonType.PRIMARY} handler={redirectToGallery}/>
        </div>
    );
};
