import React, {useEffect, useState} from "react";

import {ConfigContext, DEFAULT_APP_CONFIG} from "./config-context";

interface Props {
    children: React.ReactNode
}

export const ConfigContextProvider = ({children}: Props) => {
    const [appConfig, setAppConfig] = useState(DEFAULT_APP_CONFIG);

    useEffect(() => {
        fetch('/app-config.json')
            .then((response) => response.json())
            .then((data) => setAppConfig(data))
            .catch((error) => console.error('Error loading app config:', error));
    }, []);

    return (
        <ConfigContext.Provider value={appConfig}>
            {children}
        </ConfigContext.Provider>
    )
}