import React from 'react';

interface Props {
    title: string,
    children: React.ReactNode
}

export const FormItem = ({title, children}: Props) => {
    return (
        <div>
            <span><b>{title}</b></span>
            {children}
        </div>
    );
};
