import {useContext, useMemo} from 'react';

import {ComboBox} from '../../components/combobox';
import {FormItem} from '../landing/components/form';
import {GalleryFiltersContext} from '../../contexts/gallery-filters-context';
import {ConfigContext} from '../../contexts/config-context';

import './filters.css';

export const Filters = () => {
    const {filterByType, filterByCategory, setTypeFilter, setCategoryFilter} = useContext(GalleryFiltersContext);
    const appConfig = useContext(ConfigContext);

    const filterTypes = useMemo(() => appConfig.db.itemTypes.map(({typeId, name}) => ({
        code: typeId.toString(),
        description: name
    })), [appConfig]);

    const filterCategories = useMemo(() => appConfig.db.itemCategories.map(({categoryId, name}) => ({
        code: categoryId.toString(),
        description: name
    })), [appConfig]);

    return (
        <div className="filters">
            <h1>{appConfig.search.filtersTitle}</h1>
            <div className="filters__container">
                <FormItem title={appConfig.search.filterTypeTitle}>
                    <ComboBox
                        value={filterByType.toString()}
                        onChange={(value) => setTypeFilter(+value)}
                        placeholder="Select type"
                        items={filterTypes}
                        width={300}
                    />
                </FormItem>
                <FormItem title={appConfig.search.filterCategoryTitle}>
                    <ComboBox
                        value={filterByCategory.toString()}
                        onChange={(value) => setCategoryFilter(+value)}
                        placeholder="Select category"
                        items={filterCategories}
                        width={300}
                    />
                </FormItem>
            </div>
        </div>
    );
}