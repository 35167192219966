import React, {useContext, useState} from 'react';

import {ConfigContext, DBItem} from '../../contexts/config-context/config-context';
import {Swipebox} from '../../components/swipebox';
import {GalleryIndicator} from "../landing/components/gallery-preview/gallery-indicator";

interface Props {
    item: DBItem
}

export const GalleryItem = ({item}: Props) => {
    const appConfig = useContext(ConfigContext);
    const [currentImg, setCurrentImg] = useState(0);

    return (
        <div className="gallery__item">
            <div>
                <Swipebox
                    itemId={currentImg}
                    items={item.images.slice(1).map((img, id) => ({id, img}))}
                    onChange={setCurrentImg}
                    height={400}
                />
                <span className="gallery-item__cost">
                    <strong>
                        {appConfig.gallery.item.cost.label}
                    </strong>
                    <strong className="gallery-item-cost__amount">
                        {item.cost}  {appConfig.gallery.item.cost.currency}
                    </strong>
                </span>
            </div>
            <ul className="gallery-preview__indicators">
                {item.images.slice(1).map((img, i) => (
                    <GalleryIndicator
                        key={i}
                        isSelected={i === currentImg}
                        onClick={() => setCurrentImg(i)}
                    />
                ))}
            </ul>
            <h4>{item.title}</h4>
            <p>
                <a href={appConfig.gallery.item.colors.href} target="_blank">
                    {appConfig.gallery.item.colors.label}
                </a>
            </p>
            <section>
                <p>{appConfig.gallery.item.sizes.label}</p>
                <ul className="gallery-item__ul">
                    {item.sizes.map((size, i) => (
                        <li className="gallery-item__li" key={i}>{size}</li>
                    ))}
                </ul>
            </section>
            <section>
                <p>{appConfig.gallery.item.description.label}</p>
                <ul className="gallery-item__ul">
                    {item.descriptions.map((size, i) => (
                        <li className="gallery-item__li" key={i}>{size}</li>
                    ))}
                </ul>
            </section>
        </div>
    );
}