export const debounce = (callback: () => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;

    // @ts-ignore
    return function(...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            // @ts-ignore
            callback.apply(this, args);
        }, delay);
    };
};
