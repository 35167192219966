import {Contacts} from '../../../../components/contacts';

import {ContactsData, LandingPageSection} from '../../../../contexts/config-context/config-context';

import './contacts.css';
import {useContext} from "react";
import {DeviceContext} from "../../../../contexts/device-context";
import clsx from "clsx";

interface Props {
    section: LandingPageSection<ContactsData>
}

export const ContactsSection = ({section}: Props) => {
    const {isDesktop} = useContext(DeviceContext);

    const classes = clsx('contacts-section', [
        isDesktop && 'contacts-section_desktop'
    ]);
    return (
        <section className={classes} id={section.href}>
            <h1 className="page-section__header">{section.title}</h1>
            <div className="contacts__content">
                <div className="contacts__gmap">
                    <iframe
                        src={section.content.gmap}
                        width="100%"
                        height="320"
                        style={{border: 0}}
                        allowFullScreen={true}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
                <Contacts/>
            </div>
        </section>
    );
};
