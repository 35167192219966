import {createContext} from 'react';

interface GalleryFilterContextType {
    filterByType: number,
    filterByCategory: number,
    setTypeFilter: (type: number) => void,
    setCategoryFilter: (type: number) => void
}

const DEFAULT_FILTERS_CONTEXT = {
    filterByType: 0,
    filterByCategory: 0,
    setTypeFilter: () => undefined,
    setCategoryFilter: () => undefined
}
export const GalleryFiltersContext = createContext<GalleryFilterContextType>(DEFAULT_FILTERS_CONTEXT);
