import clsx from 'clsx';
import {useContext} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faLinkedin} from '@fortawesome/free-brands-svg-icons';

import {DeviceContext} from '../../contexts/device-context';
import Logo from '../../images/logo.png';
import SrgLogo from '../../images/srg-logo.jpg';
import {Contacts} from '../contacts';

import './footer.css';

export const Footer = () => {
    const {isDesktop} = useContext(DeviceContext);
    const footerClasses = clsx([
        isDesktop ? 'footer_desktop' : 'footer_mobile'
    ]);

    return (
        <footer className={footerClasses}>
            <div className="footer__sections">
                <section className="footer__logos">
                    <img className="footer__logo" src={Logo} alt="srg-logo"/>
                    <img className="footer__logo" src={SrgLogo} alt="gelostyle-logo"/>
                </section>
                <section>
                    <Contacts/>
                </section>
                <section className="footer__social-media">
                    <a className="footer-social-media__link" href="https://instagram.com/gelo_style_meubles">
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                </section>
            </div>
            <span className="footer__powered-by">
                <FontAwesomeIcon icon={faLinkedin}/> <span>
                    Powered by <a href="https://www.linkedin.com/in/andrii-yeromenko-46aa58193/">Andrii Yeromenko</a>
                </span>
            </span>
            <span>© Société SRG 2023</span>
        </footer>
    )
};
