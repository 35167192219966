import {useContext, useMemo} from 'react';

import {ConfigContext} from '../../contexts/config-context';
import {GalleryItem} from './gallery-item';

import './gallery.css';
import {GalleryFiltersContext} from "../../contexts/gallery-filters-context";

export const Gallery = () => {
    const appConfig = useContext(ConfigContext);
    const {filterByType, filterByCategory} = useContext(GalleryFiltersContext);

    const galleryItems = useMemo(() => (
        appConfig.db.items
            .filter(({typeId}) => filterByType ? typeId === filterByType : true)
            .filter(({id}) => appConfig.db.itemCategories
                .find(({categoryId}) => categoryId === filterByCategory)?.itemIds.includes(id))
    ), [appConfig, filterByCategory, filterByType]);

    return (
        <section className="gallery" id="gallery">
            <h1>Galerie</h1>
            {!!filterByCategory &&
                <h4>
                    {appConfig.db.itemCategories.find(({categoryId}) => categoryId === filterByCategory)?.name}
                </h4>
            }
            <div className="gallery__container">
                {galleryItems.map((item) => (
                    <GalleryItem key={item.id} item={item}/>
                ))}
            </div>
        </section>
    );
}