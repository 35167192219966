import {createContext} from 'react';

interface Link {
    href: string,
    hash: string,
    title: string
}

export interface LandingPageSection<T> {
    href: string,
    title: string,
    content: T
}

export interface OfferItem {
    categoryId: number,
    description: string,
    img: string
}

export interface ContactsData {
    gmap: string,
    address: string,
    phone: string,
    phoneLabel: string,
    email: string
}

export interface DBItemSize {
    value: number,
    units: string
}

export interface DBItem {
    id: number,
    typeId: number,
    categoryId: number,
    images: string[],
    title: string,
    sizes: string[],
    descriptions: string[],
    cost: string
}

export interface AppConfig {
    header: Link[]
    search: {
        searchTitle: string,
        filtersTitle: string,
        filterTypeTitle: string,
        filterCategoryTitle: string
    },
    landingPage: {
        sections: {
            gallery: number[],
            solutions: LandingPageSection<OfferItem[]>,
            aboutSRG: LandingPageSection<string[]>
            aboutGeloStyle: LandingPageSection<{
                description: string,
                materials: {
                    header: string,
                    description: string,
                    items: string[]
                },
                footer: string
            }>
            contacts: LandingPageSection<ContactsData>
        }
    },
    gallery: {
        item: {
            sizes: {
                label: string
            },
            description: {
                label: string
            },
            colors: {
                label: string,
                href: string
            },
            cost: {
                label: string,
                currency: string
            }
        }
    },
    db: {
        itemTypes: {typeId: number, name: string}[],
        itemCategories: {categoryId: number, name: string, itemIds: number[]}[],
        items: DBItem[]
    }
}

export const DEFAULT_APP_CONFIG: AppConfig = {
    header: [],
    search: {
        searchTitle: "",
        filtersTitle: "",
        filterTypeTitle: "",
        filterCategoryTitle: ""
    },
    landingPage: {
        sections: {
            gallery: [],
            solutions: {
                href: "",
                title: "",
                content: []
            },
            aboutSRG: {
                href: "",
                title: "",
                content: []
            },
            aboutGeloStyle: {
                href: "",
                title: "",
                content: {
                    description: "",
                    materials: {
                        header: "",
                        description: "",
                        items: []
                    },
                    footer: ""
                }
            },
            contacts: {
                href:  "",
                title: "",
                content: {
                    gmap: "",
                    address: "",
                    phone: "",
                    phoneLabel: "",
                    email: ""
                }
            }
        }
    },
    gallery: {
        item: {
            sizes: {
                label: ""
            },
            description: {
                label: ""
            },
            colors: {
                label: "",
                href: ""
            },
            cost: {
                label: "",
                currency: ""
            }
        }
    },
    db: {
        itemTypes: [],
        itemCategories: [],
        items: []
    }
}
export const ConfigContext = createContext<AppConfig>(DEFAULT_APP_CONFIG);