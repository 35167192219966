import {GalleryFiltersContext} from "./gallery-filter-context";
import React, {useState} from "react";

interface Props {
    children: React.ReactNode;
}

export const GalleryFiltersContextProvider = ({children}: Props) => {
    const [filterByType, setTypeFilter] = useState(0);
    const [filterByCategory, setCategoryFilter] = useState(0);

    return (
        <GalleryFiltersContext.Provider value={{filterByType, filterByCategory, setTypeFilter, setCategoryFilter}}>
            {children}
        </GalleryFiltersContext.Provider>
    )
}