import {useContext, useState} from 'react';

import {GalleryIndicator} from "./gallery-indicator";
import {Swipebox} from '../../../../components/swipebox';

import './gallery-preview.css';

import type {DBItem} from '../../../../contexts/config-context/config-context';
import {DeviceContext} from "../../../../contexts/device-context";

interface Props {
    galleryItems: DBItem[]
}

export const GalleryPreview = ({galleryItems}: Props) => {
    const [currentItemId, setCurrentItemId] = useState(0);
    const {isDesktop} = useContext(DeviceContext);

    return (
        <section className="gallery-preview">
            <Swipebox
                height={isDesktop ? 500 : 400}
                itemId={currentItemId}
                onChange={setCurrentItemId}
                items={galleryItems.map(({id, images}) => ({id, img: images[isDesktop
                    ? 0
                    : 1
                ]}))}
            />
            <ul className="gallery-preview__indicators">
                {galleryItems.map(({id}) => (
                    <GalleryIndicator
                        key={id}
                        isSelected={id === currentItemId}
                        onClick={() => setCurrentItemId(id)}
                    />
                ))}
            </ul>
            <p>
                {galleryItems[currentItemId]?.title}
            </p>
        </section>
    );
}