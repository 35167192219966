import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import {DeviceContextProvider} from './contexts/device-context';
import {ConfigContextProvider} from './contexts/config-context';
import {GalleryFiltersContextProvider} from './contexts/gallery-filters-context';
import {Header} from './components/header';
import {Footer} from "./components/footer";
import {Router} from './modules/router/router';

import './App.css';

const App = () => (
    <ConfigContextProvider>
        <DeviceContextProvider>
            <GalleryFiltersContextProvider>
                <div className="app">
                    <BrowserRouter>
                        <Header/>
                        <div style={{width: '100%', height: '82px'}}/>
                        <Router/>
                        <Footer/>
                    </BrowserRouter>
                </div>
            </GalleryFiltersContextProvider>
        </DeviceContextProvider>
    </ConfigContextProvider>
);

export default App;
