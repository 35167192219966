import {Solution} from './solution';

import './solutions.css';

import type {OfferItem} from '../../../../contexts/config-context/config-context';

interface Props {
    items: OfferItem[]
}

export const Solutions = ({items}: Props) => {
    return (
        <div className="solutions">
            {items.map((offer) => (
                <Solution key={offer.categoryId} offer={offer}/>
            ))}
        </div>
    )
}