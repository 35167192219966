import {Routes, Route} from 'react-router-dom';

import {Landing} from '../landing';
import {Gallery} from '../gallery';

export const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing/>}/>
            <Route path="/gallery" element={<Gallery/>}/>
        </Routes>
    );
}