import {useContext} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressCard, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';

import {ConfigContext} from '../../contexts/config-context';

import './contacts.css';

export const Contacts = () => {
    const appConfig = useContext(ConfigContext);

    return (
        <ul className="contacts__ul">
            <li className="contacts__li">
                <FontAwesomeIcon icon={faAddressCard}/>
                <div className="contacts__item">
                    {appConfig.landingPage.sections.contacts.content.address
                        .split('\n')
                        .map((line, i) => <div key={i}>{line}</div>)
                    }
                </div>
            </li>
            <li className="contacts__li">
                <FontAwesomeIcon icon={faPhone}/>
                <a
                    className="contacts__item"
                    href={`tel:${appConfig.landingPage.sections.contacts.content.phone}`}>
                    {appConfig.landingPage.sections.contacts.content.phoneLabel}
                </a>
            </li>
            <li className="contacts__li">
                <FontAwesomeIcon icon={faEnvelope}/>
                <a
                    className="contacts__item"
                    href={`mailto:${appConfig.landingPage.sections.contacts.content.email}`}>
                    {appConfig.landingPage.sections.contacts.content.email}
                </a>
            </li>
        </ul>
    )
}