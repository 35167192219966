import {useContext, useMemo} from 'react';

import {ConfigContext} from '../../contexts/config-context';
import {GalleryPreview} from './components/gallery-preview';
import {Solutions} from './components/solutions';
import {LandingSection} from './components/landing-section/landing-section';
import Logo from '../../images/logo.png';
import {ContactsSection} from "./components/contacts/contacts";

export const Landing = () => {
    const appConfig = useContext(ConfigContext);

    const galleryItems = useMemo(() => (
        appConfig.db.items
            .filter(({id}) => appConfig.landingPage.sections.gallery.includes(id))
    ), [appConfig]);

    return (
        <>
            <GalleryPreview galleryItems={galleryItems}/>
            <LandingSection
                href={appConfig.landingPage.sections.solutions.href}
                title={appConfig.landingPage.sections.solutions.title}>
                <Solutions items={appConfig.landingPage.sections.solutions.content}/>
            </LandingSection>
            <LandingSection
                href={appConfig.landingPage.sections.aboutSRG.href}
                title={appConfig.landingPage.sections.aboutSRG.title}>
                {appConfig.landingPage.sections.aboutSRG.content.map((text, i) => (
                    <p key={i}>{text}</p>
                ))}
            </LandingSection>
            <LandingSection
                href={appConfig.landingPage.sections.aboutGeloStyle.href}
                title={appConfig.landingPage.sections.aboutGeloStyle.title}>
                <img
                    width={200}
                    height={200}
                    src={Logo}
                    alt="gelostyle-logo"
                />
                <br/>
                <div>
                    <p>{appConfig.landingPage.sections.aboutGeloStyle.content.description}</p>
                    <h4>{appConfig.landingPage.sections.aboutGeloStyle.content.materials.header}</h4>
                    <p>{appConfig.landingPage.sections.aboutGeloStyle.content.materials.description}</p>
                    <ul>
                        {appConfig.landingPage.sections.aboutGeloStyle.content.materials.items.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                    </ul>
                    <p>{appConfig.landingPage.sections.aboutGeloStyle.content.footer}</p>
                </div>
            </LandingSection>
            <ContactsSection section={appConfig.landingPage.sections.contacts}/>
        </>
    );
}