import {useCallback, useEffect, useRef, useState} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';

import './combobox.css';

import type {ComboBoxItem} from './typedef';

interface Props {
    value: string,
    placeholder: string,
    items: ComboBoxItem[],
    width: number,
    onChange: (value: string) => void
}

export const ComboBox = ({value, items, placeholder, width, onChange}: Props) => {
    const [shownOptions, setShownOptions] = useState(false);
    const optionsRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setShownOptions(false);
            }
        }

        document.addEventListener("mouseup", handleOutsideClick);

        return () => {
            document.removeEventListener("mouseup", handleOutsideClick);
        };
    }, []);

    const toggleOptions = useCallback(() => {
        setShownOptions(prevState => !prevState);
    }, []);

    const selectOption = useCallback((code: string) => {
        onChange(code);
        setShownOptions(false);
    }, []);

    return (
        <div className="combobox" style={{width}}>
            <span className="combobox__select" onClick={toggleOptions}>
                {value ? items.find(({code}) => code === value)?.description : placeholder}
                <FontAwesomeIcon
                    icon={shownOptions ? faChevronUp : faChevronDown}
                    fontSize={14}
                />
            </span>
            {shownOptions && (
                <ul ref={optionsRef} className="combobox__options" style={{width: width - 4}}>
                    {items.map(({code, description}) => (
                        <li key={code} onClick={() => selectOption(code)} className="combobox__option">
                            {description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}