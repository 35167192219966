import clsx from 'clsx';
import {HashLink} from 'react-router-hash-link';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faBars, faSearch} from '@fortawesome/free-solid-svg-icons';

import SrgLogo from '../../images/srg-logo.jpg';
import {Filters} from '../../modules/filters';
import {DeviceContext} from '../../contexts/device-context';
import {ConfigContext} from '../../contexts/config-context';

import  './header.css';

export const Header = () => {
    const {pathname} = useLocation();
    const appConfig = useContext(ConfigContext);
    const {isDesktop} = useContext(DeviceContext);

    const [showNavigation, setShowNavigation] = useState(false);
    const [showSearchFilters, setShowSearchFilters] = useState(false);
    const [showSearchFiltersContent, setShowSearchFiltersContent] = useState(false);

    let timer = useRef<NodeJS.Timeout>();

    const openMobileNav = useCallback(() => {
        setShowNavigation(true);

        if (showSearchFilters) {
            setShowSearchFilters(false);
            setShowSearchFiltersContent(false);
        }
    }, [showSearchFilters]);

    const closeMobileNav = useCallback(() => {
        setShowNavigation(false);
    }, []);

    const openSearchFilters = useCallback(() => {
        setShowSearchFilters(true);
        timer.current = setTimeout(() => setShowSearchFiltersContent(true), 300);

        if (showNavigation) {
            setShowNavigation(false);
        }
    }, [showNavigation]);

    const closeSearchFilters = useCallback(() => {
        setShowSearchFilters(false);
        setShowSearchFiltersContent(false);
    }, []);

    const headerContentClasses = clsx('header__content', [
        isDesktop ? 'header__content_desktop' : 'header__content_mobile'
    ]);

    const searchClasses = clsx('header__search-body', [
        isDesktop && showSearchFilters && 'header__search-body_desktop',
        !isDesktop && showSearchFilters && 'header__search-body_mobile'
    ]);

    const isGalleryRoute = pathname.includes('gallery');

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        }
    }, []);

    return (
        <header className="header_sticky">
            <div className={headerContentClasses}>
                {!isDesktop && (
                    <span style={{width: 32}}>
                        {showNavigation ? (
                            <span onClick={closeMobileNav}>
                                <FontAwesomeIcon icon={faTimes} fontSize={24}/>
                            </span>
                        ) : (
                            <span onClick={openMobileNav}>
                                <FontAwesomeIcon icon={faBars} fontSize={24}/>
                            </span>
                        )}
                    </span>
                )}
                <HashLink smooth to="/#top">
                    <img src={SrgLogo} width="64" height="40" alt="srg-logo"/>
                </HashLink>
                {isDesktop && (
                    <nav>
                        <ul className="header__nav-ul">
                            {appConfig.header.map(({href, title, hash}, i) => (
                                <li className="header__nav-li" key={i}>
                                    {hash ? (
                                        <HashLink smooth to={href + '#' + hash} className="header__nav-a">
                                            {title}
                                        </HashLink>
                                    ) : (
                                        <Link to={href} className="header__nav-a">{title}</Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
                {isDesktop ? (
                    <span style={{width: 100}}>
                        {isGalleryRoute && (showSearchFilters ? (
                            <span onClick={closeSearchFilters} className="header__search-button">
                                <FontAwesomeIcon icon={faTimes} fontSize={24}/>
                                <span className="header__search-button-title">{appConfig.search.searchTitle}</span>
                            </span>
                        ) : (
                            <span onClick={openSearchFilters} className="header__search-button">
                                <FontAwesomeIcon icon={faSearch} fontSize={24}/>
                                <span className="header__search-button-title">{appConfig.search.searchTitle}</span>
                            </span>
                        ))}
                    </span>
                ) : (
                    <span style={{width: 32}}>
                        {isGalleryRoute && (showSearchFilters ? (
                            <span onClick={closeSearchFilters}>
                                <FontAwesomeIcon icon={faTimes} fontSize={24}/>
                            </span>
                        ) : (
                            <span onClick={openSearchFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={24}/>
                            </span>
                        ))}
                    </span>
                )}
            </div>
            {showNavigation && (
                <aside className="header__nav-body_mobile">
                    <nav>
                        <ul className="header__nav-ul_mobile">
                            {appConfig.header.map(({href, title, hash}, i) => (
                                <li key={i} className="header__nav-li_mobile" onClick={closeMobileNav}>
                                    {hash ? (
                                        <HashLink smooth to={href + '#' + hash} className="header__nav-a_mobile">
                                            {title}
                                        </HashLink>
                                    ) : (
                                        <Link to={href} className="header__nav-a_mobile">{title}</Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>
            )}
            <aside className={searchClasses}>
                {showSearchFiltersContent && <Filters/>}
            </aside>
        </header>
    );
};
